@import url('https://fonts.googleapis.com/css2?family=Work+Sans&family=League+Spartan&family=Open+Sans:wght@400;500;600;700&display=swap');


@font-face {
    font-family: 'Mongoose';
    src: local('Mongoose'), local('FontName'),
    url('../fonts/Mongoose/Mongoose-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Neue';
    src: local('Neue'), local('FontName'),
    url('../fonts/Neue/NHaasGroteskDSPro-45Lt.otf') format('opentype');
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Mediu.eot');
    src: local('NeueHaasDisplay-Mediu'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Mediu.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Mediu.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Mediu.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Mediu.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-RomanItalic.eot');
    src: local('NeueHaasDisplay-RomanItalic'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-RomanItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-RomanItalic.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-RomanItalic.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-RomanItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Black.eot');
    src: local('NeueHaasDisplay-Black'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Black.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Black.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-MediumItalic.eot');
    src: local('NeueHaasDisplay-MediumItalic'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-MediumItalic.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-MediumItalic.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-BlackItalic.eot');
    src: local('NeueHaasDisplay-BlackItalic'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-BlackItalic.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-BlackItalic.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Roman.eot');
    src: local('NeueHaasDisplay-Roman'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Roman.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Roman.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Roman.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Roman.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-BoldItalic.eot');
    src: local('NeueHaasDisplay-BoldItalic'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-BoldItalic.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-BoldItalic.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-LightItalic.eot');
    src: local('NeueHaasDisplay-LightItalic'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-LightItalic.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-LightItalic.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Bold.eot');
    src: local('NeueHaasDisplay-Bold'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Bold.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Bold.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Light.eot');
    src: local('NeueHaasDisplay-Light'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Light.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Light.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XXThin.eot');
    src: local('NeueHaasDisplay-XXThin'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XXThin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XXThin.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XXThin.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XXThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XThinItalic.eot');
    src: local('NeueHaasDisplay-XThinItalic'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XThinItalic.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XThinItalic.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XXThinItalic.eot');
    src: local('NeueHaasDisplay-XXThinItalic'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XXThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XXThinItalic.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XXThinItalic.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XXThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XThin.eot');
    src: local('NeueHaasDisplay-XThin'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XThin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XThin.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XThin.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-XThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Thin.eot');
    src: local('NeueHaasDisplay-Thin'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Thin.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Thin.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-ThinItalic.eot');
    src: local('NeueHaasDisplay-ThinItalic'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-ThinItalic.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-ThinItalic.woff') format('woff'),
    url('../fonts/Neue_Haas_Grotesk_Display_Pro/NeueHaasDisplay-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

